import React, { useState, useEffect, useCallback, useRef } from "react"

import VehicleRecommendation from "../components/recommendation/VehicleRecommendation"
import VDPModalContainer from "../components/vdp/VDPModalContainer"

import { ModalVehicleProvider } from "../components/modal/storage/VehicleContext"

export default function SearchRecommended({ props }) {
  const searchdata = typeof props !== 'undefined' ? props : {
    siteSettings: { theme: { colors: {} } },
    StateResults: [],
    allDataDict: [],
    setFavoriteItems: () => { },
    favoriteItem: () => { },
    msrpClass: () => { },
    formatPrice: () => { },
    sortTheItems: () => { },
    useCompactCards: false,
    additionalInventory: false
  }

  const { rates, downpayment, defaultMonth } = searchdata
  const { disclaimerCalculator, disclaimerContact, disclaimerFinanceApp, HideNewUsed } = searchdata
  const { lead_url, site_id, gtmId } = searchdata
  const { filterState, overwriteCTAs, overwriteInlineOffers, staticBtns, staticSrpInlineOffers} = searchdata
  const { additionalInventory } = searchdata

  const localStorage = typeof window !== 'undefined' && window.localStorage
  let colorTheme = searchdata.siteSettings.theme.colors

  let offers = overwriteInlineOffers? staticSrpInlineOffers.inlineOffers 
  : searchdata.siteSettings.inlineOffers? searchdata.siteSettings.inlineOffers.
  slideList : []
  let freq = overwriteInlineOffers? staticSrpInlineOffers.freq 
  : searchdata.siteSettings.inlineOffers? searchdata.siteSettings.inlineOffers.freq : 0
  let offerSettings = searchdata.siteSettings.inlineOffers

  //hard set as false until v2 cards properly added.
  let useCompactCards = false;

  const { StateResults } = searchdata
  const { theSlug, formatPrice, allDataDict, sortTheItems } = searchdata
  const { SRPCardBtns } = searchdata
  const [hasMounted, setHasMounted] = React.useState(false);
  const [page, setPage] = useState(1)
  const [StateFavorites, setFavorites] = useState(getFavorites());

  function getFavorites() {
    let favs = getStorageItem("favoriteItem")
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    return theFavs
  }

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
  }

  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
      ? localStorage.setItem(item, value)
      : console.log('no localStorage')
  }

  function checkDates(list) {
    const today = new Date(Date.now()).getTime()
    let currentOffers = []
    if(typeof list !== 'undefined') {
      list.forEach(slide => {       
          const expiration = slide.expiration != '' ? new Date(slide.expiration) : new Date('01/01/1900')
          const startDate = slide.startDate != '' ? new Date(slide.startDate) : new Date()

          startDate.setHours(0,0,0,0)
          expiration.setHours(23,59,59,59)

          if (startDate.getTime() <= today && today < expiration.getTime()) currentOffers.push(slide) // pushed slide into new array if today fits between expiration and start date
      })
    }
    return currentOffers
}

function checkFilters(list) {
let filteredOffers = []
if(typeof list !== 'undefined') {
  list.forEach(slide => {
    let filter = slide.hasOwnProperty('showOn') ? slide.showOn : '' //null handling
    //if used and certified
    if(filter === 'UC' && (!filterState.new)) {
      filteredOffers.push(slide)
    }
    //if used
    else if(filter === 'U' && filterState.used && !filterState.new) {
      filteredOffers.push(slide)
    }
    //if certified
    /*else if(filter === 'C' && filterState.certified && filterState.used && !filterState.new) {
      filteredOffers.push(slide)
    }*/
    //if new
    else if(filter === 'N' && !filterState.used && filterState.new) {
      filteredOffers.push(slide)
    }
    //If all or any
    else if(filter === 'ALL') {
      filteredOffers.push(slide)
    }
  })
}
return filteredOffers
}

function generateOfferCards(adList) {
if(adList.length > 0) {
return ( adList.map((result, index) => {
    let structuredCard = result.htmlAd == '' ? <li className={`srp-offer-card sr-item ${useCompactCards ? "v2" : ""}`}>
      <a href={result.imageLink == '' ? '' : result.imageLink}>
        <img className="srp-offer-card-image" src={result.imageSrc}/>
      </a>
      </li> : <li className={`srp-offer-card sr-item ${useCompactCards ? "v2" : ""}`} dangerouslySetInnerHTML={{ __html: result.htmlAd }}></li>
      //console.log(structuredCard)
      return structuredCard
    }
  ))}
}

function generateInventoryCards(searchResults) {
  let returnList = searchResults.map((result, index) => {
    //console.log(searchResults)
    let item = allDataDict[result.ref]
    let cnt = (6 * page) - 3
    return (
      item !== undefined ?
      <li ref={index == cnt ? lastItem : {}} className={`sr-item v2 ${item.VehicleInfo.IsNew ? 'new-srp-item' : 'used-srp-item'} sr-item_page_` + Math.ceil((index + 1) / 10)}>
        {
        <VehicleRecommendation 
        showTrim={props.showTrim}
        showSpecs={props.showSpecs}
        fallbackText={props.fallbackText}
        isFavorite={isFavorite(item.VehicleInfo.VIN)}
        favorite={favoriteItem}
        theSlug={theSlug}
        item={item}
        unavailableImg={props.unavailableImg}
        HideNewUsed={HideNewUsed}
        SRPCardBtns={SRPCardBtns}
        formatPrice={formatPrice}
        AutoCheckEnabled={props.AutoCheckEnabled}
        overwriteCTAs={overwriteCTAs}
        staticBtns={staticBtns}
        additionalInventory={additionalInventory}
      />
      }
      </li> : null
    )
  })
  return returnList
}

function addInlineOffers(offersList, inventoryList, offerFreq) {
  let newList = []
  const totalLength = (typeof offersList !== 'undefined' ? offersList.length : 0) + inventoryList.length
  let offerCounter = 0
  let inventoryCounter = 0
  let freqCounter = 0

  for(let i = 0; i < totalLength; i++) {
    if (offerCounter >= (typeof offersList !== 'undefined' ? offersList.length : 0)) {
      newList.push(inventoryList[inventoryCounter])
      inventoryCounter++;
    }
    //insert else-if to reset offerscounter to expand for looping.
    else {
      if (freqCounter == offerFreq && i != 0) {
        newList.push(offersList[offerCounter])
        offerCounter++;
        freqCounter = 0;
      }
      else {
        if (inventoryCounter < inventoryList.length) {
          newList.push(inventoryList[inventoryCounter])
          inventoryCounter++;
          freqCounter++;
        }
      }
    }
  }

  return newList
}

  function isFavorite(vin) {
    return StateFavorites.findIndex((v) => (v == vin)) !== -1
  }

  function favoriteItem(vin) {
    let favs = getStorageItem("favoriteItem")
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    theFavs = theFavs.filter((value, index, self) => self.indexOf(value) === index)
    if (!theFavs.includes(vin)) {          //checking weather array contain the id
      theFavs.push(vin)
    } else {
      theFavs.splice(theFavs.indexOf(vin), 1);  //deleting
    }
    setStorageItem("favoriteItem", JSON.stringify(theFavs));
    setFavorites(theFavs);
    props.setFavorites(theFavs)
  }

  const observer = useRef()
  const lastItem = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage(page + 1)
      }
    });
    if (node)
      observer.current.observe(node);
  }
  )

  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <>
      <ModalVehicleProvider>
        <VDPModalContainer
          siteData={{
            lead_url, site_id, gtmId,
            disclaimerContact, disclaimerFinanceApp, disclaimerCalculator
          }}
          financeData={{ rates, downpayment, defaultMonth }}
        />
        <section className="sr-container">
          <ul className="sr">
            {addInlineOffers(
              generateOfferCards(checkFilters(checkDates(offers))),
              generateInventoryCards(sortTheItems(StateResults).slice(0, Math.min(6 * page, StateResults.length))),
              freq
            )}
          </ul>
        </section>
      </ModalVehicleProvider>
    </>
  )
}
